import React, { useRef } from "react"
import { Link, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { AiOutlineSwapRight } from "react-icons/ai"
import { IconContext } from "react-icons"

import { media } from "../styles/media"
import { brandPagePaths, pagePath, externalLinkPath } from "../constants/link"

// const MenuArrow = () => (
//   <IconContext.Provider value={{ color: "#E4D8B9" }}>
//     <AiOutlineSwapRight />
//   </IconContext.Provider>
// )

const StyledMenuListContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  height: 100dvh;
`

const StyledListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-left: 10%;
  &:last-of-type {
    margin-bottom: 30px;
  }
`

const StyledBorder = styled.div`
  width: 100%;
  max-width: 468px;
  border-bottom: 0.5px solid #fff;
  margin-inline-start: 16px;
  margin-bottom: 14px;

  &.bottomBorder {
    margin-top: 14px;
  }
`

const StyledMenuList = styled.div`
  background: rgba(102, 49, 0, 0.95);
  box-shadow: -1px 0px 10px black;
  max-width: 500px;
  height: 100dvh;
  justify-content: flext-start;
  position: fixed;
  top: 0;
  right: -600px;
  animation: ${props => {
    if (props.isMenuListActive !== null) {
      if (props.isMenuListActive) {
        return `menu-slide-in 0.3s linear 0s forwards`
      }
      return `menu-slide-out 0.3s linear 0s forwards`
    }
  }};
  z-index: 1000;
  @keyframes menu-slide-in {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-600px);
    }
  }
  @keyframes menu-slide-out {
    from {
      transform: translateX(-600px);
    }
    to {
      transform: translateX(0);
    }
  }
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  .navLists {
    width: 100%;
    padding: 10px 16px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    font-size: 1em;
    span {
      color: #663100;
      width: 100%;
    }
    .nonLink {
      margin-bottom: 10px;
      color: ${({ theme }) => theme.colors.light.brown};
    }
    .toLink {
      color: white;
      text-align: center;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      a {
        padding: 0;
        margin: 0;
      }
    }
    .links {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin-left: 10%;
      a {
        width: 40%;
        color: white;
      }
    }
    a {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: flex-start;
      margin: 8px 0px 20px;
      svg {
        margin-left: 0.12em;
      }
    }
  }
  ${() =>
    media.sp(css`
      width: 100%;
    `)}
`

const StyledNavBtn = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-inline-start: auto;
  margin-inline-end: 5%;
  margin-block: 10px;
  height: 60px;
  z-index: 1100;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  #btnDesign {
    width: 50px;
    height: 18px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    position: relative;
    justify-content: center;
    span {
      height: 2px;
      border-radius: 1px;
      width: 60%;
    }

    .btnTop {
      background: ${({ theme }) => theme.colors.primary.red};
      width: 15px;
      height: 15px;
      border-radius: 15px;
    }
    .btnBottom {
      background: ${({ theme }) => theme.colors.primary.green};
      width: 15px;
      height: 15px;
      border-radius: 15px;
    }
  }
`

const renderPageLink = (path, text, isExternal = false) => {
  return (
    <Link to={path}>
      {text}
      {/* <MenuArrow /> */}
    </Link>
  )
}

const StyledExternalLinkContainer = styled.span`
  display: inline-flex;
  gap: 4px;
  color: #fff;
  a {
    color: #fff;
  }
`

const ExternalLink = ({ href, text }) => {
  const icon = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "external-link.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <StyledExternalLinkContainer>
      <a href={href} target="_blank" rel="noreferrer">
        {text}
      </a>
      <img src={icon.file.publicURL} alt="external-link" width="16px" />
    </StyledExternalLinkContainer>
  )
}

const MenuList = ({ isMenuListActive, setIsMenuListActive }) => {
  const buttonRef = useRef(null)
  return (
    <StyledMenuList isMenuListActive={isMenuListActive}>
      <StyledNavBtn
        ref={buttonRef}
        onClick={e => {
          if (e.currentTarget === buttonRef.current) {
            setIsMenuListActive(!isMenuListActive)
          }
        }}
        isActive={isMenuListActive}
      >
        <span>MENU</span>
        <span id="btnDesign">
          <span className="btnTop"></span>
          <span className="btnBottom"></span>
        </span>
      </StyledNavBtn>
      <StyledMenuListContainer>
        <div className="navLists">
          <span className="nonLink">フランソア・ファミリー</span>
          <div className="links">
            {brandPagePaths.map(({ id, path, text }) => (
              <Link to={path} key={id}>
                {text}
                {/* <MenuArrow /> */}
              </Link>
            ))}
          </div>
        </div>

        <div className="navLists">
          <span className="nonLink">商品検索</span>
          <div className="links">
            {renderPageLink(
              pagePath.thisMonthProducts.path,
              pagePath.thisMonthProducts.text
            )}
            {renderPageLink(
              pagePath.productSearch.path,
              pagePath.productSearch.text
            )}
            {/* {renderPageLink(
            pagePath.allergenSearch.path,
            pagePath.allergenSearch.text
          )} */}
          </div>
        </div>

        <StyledBorder />
        <StyledListContainer>
          <div className="navLists">
            <span className="toLink">
              {renderPageLink(pagePath.ourPolicy.path, pagePath.ourPolicy.text)}
            </span>
          </div>
          <div className="navLists">
            <span className="toLink">
              {renderPageLink(
                pagePath.qaOnRawMaterials.path,
                pagePath.qaOnRawMaterials.text
              )}
            </span>
          </div>
          {/* <div className="navLists">
          <span className="toLink">
            {renderPageLink(pagePath.recipeBook.path, pagePath.recipeBook.text)}
          </span>
        </div> */}

          <div className="navLists">
            <span className="toLink">
              {renderPageLink(pagePath.cmYoutube.path, pagePath.cmYoutube.text)}
            </span>
          </div>

          <div className="navLists">
            <span className="toLink">
              {renderPageLink(pagePath.inquiry.path, pagePath.inquiry.text)}
            </span>
          </div>
        </StyledListContainer>

        <StyledBorder className="bottomBorder" />
        <StyledListContainer>
          <div className="navLists">
            <span className="toLink">
              {/* {renderPageLink(
              externalLinkPath.corporateCompanyProfiles.path,
              externalLinkPath.corporateCompanyProfiles.text,
              true
            )} */}
              <ExternalLink
                href={externalLinkPath.corporateCompanyProfiles.path}
                text={externalLinkPath.corporateCompanyProfiles.text}
              />
            </span>
          </div>

          <div className="navLists">
            <span className="toLink">
              <ExternalLink
                href={externalLinkPath.sdgs.path}
                text={externalLinkPath.sdgs.text}
              />
            </span>
          </div>
          <div className="navLists">
            <span className="toLink">
              <ExternalLink
                href={externalLinkPath.recruit.path}
                text={externalLinkPath.recruit.text}
              />
            </span>
          </div>
          <div className="navLists">
            <span className="toLink">
              <ExternalLink
                href={externalLinkPath.recruitSpecial.path}
                text={externalLinkPath.recruitSpecial.text}
              />
            </span>
          </div>
          <div className="navLists">
            <span className="toLink">
              <ExternalLink
                href={externalLinkPath.news.path}
                text={externalLinkPath.news.text}
              />
            </span>
          </div>
        </StyledListContainer>
      </StyledMenuListContainer>
    </StyledMenuList>
  )
}

export default MenuList
